import styles from './Cta.module.scss';

import Column from '@/components/atoms/Column';
import Container from '@/components/atoms/Container';
import DOMPurify from 'isomorphic-dompurify';
import Row from '@/components/atoms/Row';
import cn from 'classnames';
import dynamic from 'next/dynamic';
import typeCta from '@/utilities/types/Cta';
import { CTA_STRINGS } from '@/utilities/constants/Cta';
import { Fragment } from 'react';
import { useLocale } from '@/utilities/contexts/Locale';

const Button = dynamic(() => import('@/components/atoms/Button'));
const Buttons = dynamic(() => import('@/components/molecules/Buttons'));
const Checks = dynamic(() => import('@/components/molecules/Checks'));
const Field = dynamic(() => import('@/components/atoms/Field'));
const Product = dynamic(() => import('@/components/molecules/Product'));
const Search = dynamic(() => import('@/icons/maggie/Search'));
const Typography = dynamic(() => import('@/components/atoms/Typography'));
const IconsList = dynamic(() => import('@/components/molecules/IconsList'));
const Eventbrite = dynamic(() => import('@/components/molecules/Eventbrite'));

interface CtaProps {
  className?: string;
  columns: 9 | 10 | 12;
  cta: typeCta;
  index: number;
}

const Cta = (props: CtaProps) => {
  const { className, columns, cta, index } = props;

  const locale: string = useLocale();

  return (
    <section
      className={cn(
        className,
        cta.customise.width === 'full' && styles[cta.customise.background],
        styles.root,
        styles[cta.customise.width],
      )}
      id={cta.id ? cta.id : `row-${index + 1}`}
    >
      <Container>
        <div
          className={cn(
            cta.customise.width === 'container' &&
              styles[cta.customise.background],
            styles.spacing,
          )}
        >
          <Row justify="center" xs={2}>
            <Column xs={12} lg={columns}>
              {cta.content.map((content, index) => (
                <Fragment key={index}>
                  {content.checks && (
                    <Checks checks={content.checks} className={styles.checks} />
                  )}
                  {content.iconsList && (
                    <IconsList
                      iconsList={content.iconsList}
                      className={styles.iconsList}
                    />
                  )}
                  {content?.eventbrite && content.eventbrite.id && (
                    <Eventbrite event={content.eventbrite} />
                  )}
                  {content.editor && (
                    <Typography className={styles.editor} xs={3}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(content.editor),
                        }}
                      />
                    </Typography>
                  )}
                  {content.embed && (
                    <div
                      className={styles.embed}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(content.embed, {
                          ADD_TAGS: ['iframe'],
                          ADD_ATTR: [
                            'frameborder',
                            'height',
                            'width',
                            'allow',
                            'allowfullscreen',
                            'webkitallowfullscreen',
                            'mozallowfullscreen',
                            'oallowfullscreen',
                            'msallowfullscreen',
                          ],
                        }),
                      }}
                    />
                  )}
                  {content.links && (
                    <div className={styles.links}>
                      <Buttons buttons={content.links} />
                    </div>
                  )}
                  {content.product && (
                    <Product
                      alignment="center"
                      className={styles.product}
                      product={content.product}
                    />
                  )}
                  {content.search && (
                    <form
                      action="https://public-library.safetyculture.io/search/"
                      method="get"
                      target="_blank"
                      className={styles.search}
                    >
                      <Field
                        className={styles.field}
                        name="q"
                        placeholder={content.search}
                        required={true}
                        tag="input"
                        type="search"
                      />
                      <Button
                        aria={CTA_STRINGS.search[locale]}
                        className={styles.button}
                        icon={true}
                        style="tertiary"
                        type="submit"
                      >
                        <Search height={20} width={20} />
                      </Button>
                    </form>
                  )}
                  {content.title && (
                    <Typography
                      className={styles.title}
                      family="poppins"
                      tag="h3"
                      xs={5}
                      md={6}
                      lg={7}
                    >
                      {content.title}
                    </Typography>
                  )}
                </Fragment>
              ))}
            </Column>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default Cta;
